// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-forms-add-product-js": () => import("./../src/pages/forms/add-product.js" /* webpackChunkName: "component---src-pages-forms-add-product-js" */),
  "component---src-pages-forms-add-restaurant-js": () => import("./../src/pages/forms/add-restaurant.js" /* webpackChunkName: "component---src-pages-forms-add-restaurant-js" */),
  "component---src-pages-forms-enroll-tester-js": () => import("./../src/pages/forms/enroll-tester.js" /* webpackChunkName: "component---src-pages-forms-enroll-tester-js" */),
  "component---src-pages-immunity-references-js": () => import("./../src/pages/immunity/references.js" /* webpackChunkName: "component---src-pages-immunity-references-js" */),
  "component---src-pages-meal-plans-antiviral-foods-drinks-js": () => import("./../src/pages/meal-plans/antiviral-foods-drinks.js" /* webpackChunkName: "component---src-pages-meal-plans-antiviral-foods-drinks-js" */),
  "component---src-pages-meal-plans-immunity-meal-plan-js": () => import("./../src/pages/meal-plans/immunity-meal-plan.js" /* webpackChunkName: "component---src-pages-meal-plans-immunity-meal-plan-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-comment-form-page-js": () => import("./../src/templates/comment-form-page.js" /* webpackChunkName: "component---src-templates-comment-form-page-js" */),
  "component---src-templates-ef-product-page-js": () => import("./../src/templates/ef-product-page.js" /* webpackChunkName: "component---src-templates-ef-product-page-js" */),
  "component---src-templates-form-page-js": () => import("./../src/templates/form-page.js" /* webpackChunkName: "component---src-templates-form-page-js" */),
  "component---src-templates-immunity-js": () => import("./../src/templates/immunity.js" /* webpackChunkName: "component---src-templates-immunity-js" */),
  "component---src-templates-immunity-page-js": () => import("./../src/templates/immunity-page.js" /* webpackChunkName: "component---src-templates-immunity-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-partner-page-js": () => import("./../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-product-list-page-js": () => import("./../src/templates/product-list-page.js" /* webpackChunkName: "component---src-templates-product-list-page-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-restaurant-list-page-js": () => import("./../src/templates/restaurant-list-page.js" /* webpackChunkName: "component---src-templates-restaurant-list-page-js" */),
  "component---src-templates-restaurant-post-js": () => import("./../src/templates/restaurant-post.js" /* webpackChunkName: "component---src-templates-restaurant-post-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-volunteer-form-page-js": () => import("./../src/templates/volunteer-form-page.js" /* webpackChunkName: "component---src-templates-volunteer-form-page-js" */)
}

